import React, { FC } from 'react';
import styled from 'styled-components';
import { ELayoutPosition } from 'components/ui/enum';

interface IFlex {
  className?: string;
  flex?: number;
  isPadding?: boolean;
  textPosition?: ELayoutPosition;
}

interface IFormContainerProps {
  column?: number;
  className?: string;
}

const StyledRow = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  display: flex;
  align-items: center;
`;

const StyledColumn = styled.div<{ flex: number; isPadding: boolean; textPosition: ELayoutPosition }>`
  &&&& {
    display: flex;
    flex: ${(props) => props.flex};
    padding: ${(props) => (props.isPadding ? '2em' : '5px')};
    padding-top: 0;
    justify-content: ${(props) => props.textPosition};
  }
`;

const FormWrapper = styled.div<{ column: number }>`
  display: grid;
  grid-template-columns: repeat(${(p: IFormContainerProps) => p.column}, 1fr);
  grid-column-gap: 2%;
  transition: height 0.25s;
`;

export const Row: FC<IFlex> = ({ className, flex = 1, children }) => {
  return (
    <StyledRow className={className} flex={flex}>
      {children}
    </StyledRow>
  );
};

export const Column: FC<IFlex> = ({
  className,
  flex = 1,
  isPadding = false,
  textPosition = ELayoutPosition.Left,
  children,
}) => {
  return (
    <StyledColumn className={className} flex={flex} isPadding={isPadding} textPosition={textPosition}>
      {children}
    </StyledColumn>
  );
};

export const FormContainer: FC<IFormContainerProps> = ({ children, column = 4, className }) => (
  <FormWrapper className={className} column={column}>
    {children}
  </FormWrapper>
);
export const FormContainerTest: FC<IFormContainerProps> = ({ children, column = 1, className }) => (
  <FormWrapper className={className} column={column}>
    {children}
  </FormWrapper>
);
export const FormContainerTestPhone: FC<IFormContainerProps> = ({ children, column = 1, className }) => (
  <FormWrapper className={className} column={column}>
    {children}
  </FormWrapper>
);
export const FormContainerTestperson: FC<IFormContainerProps> = ({ children, column = 1, className }) => (
  <FormWrapper className={className} column={column}>
    {children}
  </FormWrapper>
);



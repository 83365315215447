import React from 'react';
import MaskedInput from 'react-text-mask';
import { MASK } from './constant/mask.constant';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface IMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const MaskInput = {
  passport: (props: IMaskProps) => Mask(props, MASK.passport),
  idCard: (props: IMaskProps) => Mask(props, MASK.IdCard),
  currency: (props: IMaskProps) => Mask(props, createNumberMask({ prefix: '' })),
  phoneNo: (props: IMaskProps) => Mask(props, MASK.PhoneNumber),
  licensePlate: (props: IMaskProps) => Mask(props, MASK.licensePlate),
};

const Mask = (props: IMaskProps, mask: (string | RegExp)[]) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
};

export default MaskInput;

import { set } from 'lodash';
import { ChangeEvent, Dispatch, FocusEvent, SetStateAction } from 'react';
import { TDateTime, TSelect } from 'shared/types';

export const onInputChange =
  (field: string, state: any, setState: SetStateAction<any>) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    const pattern = /([^~!\s](a-zA-Zก-ฮ0-0\s)[^~!\s])/gu;
    var value = e.target.value.replaceAll(pattern, '');
    setState({ ...set(state, field, value) });
  };

export const onDateChange = (field: string, state: any, setState: SetStateAction<any>) => (value: TDateTime) => {
  setState({ ...set(state, field, value) });
};

export const onSelectChange = 
  (field: string, state: any, setState: SetStateAction<any>) =>
  (value: string | string[] | TSelect): void => {
    setState({ ...set(state, field, value) });
  };

export const onCheckboxChange =
  <T>(field: keyof T, state: T, setState: Dispatch<SetStateAction<T>>) =>
  (event: ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [field]: event.target.checked });
  };

export const onTextAreaChange =
  <T>(field: keyof T, state: T, setState: Dispatch<SetStateAction<T>>) =>
  (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setState({ ...state, [field]: event.target.value });
  };

export const validateOnBlurChange =
  (
    field: string,
    errorState: any,
    setErrorState: Dispatch<SetStateAction<any>>,
    value: any,
    validateFunc: (field: string) => string
  ) =>
  (event: FocusEvent<HTMLInputElement>): void => {
    setErrorState({
      ...errorState,
      [field]: validateFunc(value),
    });
  };

export const onCheckboxChangeHandle =
  (field: string, state: any, setState: Dispatch<SetStateAction<any>>) =>
  (event: ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, [field]: event.target.value });
  };

export const onTextChangeHandle =
  (field: string, state: any, setState: SetStateAction<any>) =>
  (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setState({ ...set(state, field, e.target.value) });
  };

export const onTextEditorChange =
  (field: string, state: any, setState: SetStateAction<any>) =>
  (value): void => {
    setState({ ...set(state, field, value) });
  };

export const onInputSearchChange =
  (field: string, state: any, setState: SetStateAction<any>) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    const pattern = /^(\s)/g;
    var value = e.target.value.replaceAll(pattern, '');
    setState({ ...set(state, field, value) });
  };

import moment from 'moment';
import {
  Address,
  AddressHouseDetail,
  AllName,
  AllNameHistory,
  BillList,
  CAList,
  Cdr,
  Datum,
  DetailList,
  House,
  ILinkHouseregistration,
  ILinkageMoveHouse,
  ILinkageNSB,
  ILinkageNSBCar,
  ILinkageNSBGunLicense,
  ILinkageNSBPensonInHouse,
  ILinkageNSBPersonDetail,
  ILinkageNSBPersonDetailID,
  ILinkageNSBPersonHouseDetail,
  ILinkageNSBPhone,
  ILinkageNSBState,
  ILinkageNSBUtilPea,
  ILinkageNSBUtilPwa,
  ILinkageNSBWarrant,
  ILinkagePersonMarried,
  ILinkagePersonPhotoRegisByPid,
  ILinkagHistory,
  ILinkMaidennameandsurname,
  Inform,
  MarriedDetail,
  MarriedLogs,
  Move,
  NameChange,
  NameSurnameChangeLogs,
  Own,
  PersonalInHouse,
  PwaCAList,
  ResponseData,
  StName,
  Ther,
  TOTAsset,
  Totba,
  Totca,
  ILinkBirthcertificate,
  ILinkDeathcertificate,
  ILinkArrestwarrant,
  ILinkCaseinformation,
} from './linkage-nsb.interface';

export const INITIAL_LINKAGE_NSB_STATE: ILinkageNSBState = {
  error: undefined,
  isPageLoading: false,
};

export const INITIAL_LINKAGE: ILinkageNSB = {
  pid: '',
  fname: '',
  lname: '',
  Oldfname: '',
  Oldlname: '',
  hid: '',
  A: '',
  D: '',
  E: '',
  F: '',
  G: '',
  H: '',
  I: '',
  J: '',
  K: '',
  L: '',
  M: '',
  N: '',
  O: '',
  record: '1',
  licenseProvinceID: null,
  AddProvinceID: null,
  AddDistrictID: null,
  AddSubdistrictsID: null,
  typeProvinceID: null,
};

export const INITIAL_LINKAGE_PersonDetail: ILinkageNSBPersonDetail = {
  age: '',
  dateOfBirth: 0,
  dateOfMoveIn: 0,
  englishFirstName: '',
  englishLastName: '',
  englishMiddleName: '',
  englishTitleDesc: '',
  fatherName: '',
  fatherNationalityCode: 0,
  fatherNationalityDesc: '',
  fatherPersonalID: 0,
  firstName: '',
  fullnameAndRank: '',
  genderCode: '',
  genderDesc: '',
  lastName: '',
  middleName: '',
  motherName: '',
  motherNationalityCode: 0,
  motherNationalityDesc: '',
  motherPersonalID: 0,
  nationalityCode: 0,
  nationalityDesc: '',
  ownerStatusDesc: '',
  statusOfPersonCode: 0,
  statusOfPersonDesc: '',
  titleCode: 0,
  titleDesc: '',
  titleName: '',
  titleSex: 0,
  pid: 0,
  totalRecord: 0,
};

export const INITIAL_PERSON_DETAIL_TEST: ILinkageNSBPersonDetailID = {
  card: {
    address: {
      alleyDesc: '',
      alleyWayDesc: '',
      districtDesc: '',
      houseNo: '',
      provinceDesc: '',
      roadDesc: '',
      subdistrictDesc: '',
      villageNo: 0,
    },
    birthDate: 0,
    blood: '',
    cancelCause: '',
    documentNumber: '',
    expireDate: '',
    foreignCountry: '',
    foreignCountryCity: '',
    issueDate: 0,
    issueTime: 0,
    nameEN: {
      firstName: '',
      lastName: '',
      middleName: '',
      title: '',
    },
    nameTH: {
      firstName: '',
      lastName: '',
      middleName: '',
      title: '',
    },
    personalID: 0,
    phoneNumber: '',
    religion: '',
    religionOther: '',
    sex: '',
  },
  houseDetail: {
    alleyCode: 0,
    alleyDesc: null,
    alleyEnglishDesc: null,
    alleyWayCode: 0,
    alleyWayDesc: null,
    alleyWayEnglishDesc: null,
    dateOfTerminate: 0,
    districtCode: 0,
    districtDesc: '',
    districtEnglishDesc: '',
    houseID: 0,
    houseNo: '',
    houseType: 0,
    houseTypeDesc: '',
    provinceCode: 0,
    provinceDesc: '',
    provinceEnglishDesc: '',
    rcodeCode: '',
    rcodeDesc: '',
    roadCode: '',
    roadDesc: null,
    roadEnglishDesc: null,
    subdistrictCode: 0,
    subdistrictDesc: '',
    subdistrictEnglishDesc: '',
    villageNo: 0,
  },
  personInHouse: [],
  image: {
    image: '',
    mineType: '',
    personalID: 0,
  },
};

export const EMPTY_BILL_LIST: BillList = {
  billPeriod: '',
  cost: 0,
  dueDate: new Date(),
  invoiceNo: '',
  paydate: new Date(),
  recordDate: new Date(),
  unit: 0,
};

export const EMPTY_CA_LIST: CAList = {
  billList: [EMPTY_BILL_LIST],
  caNo: '',
  class: '',
  companyName: '',
  distict: '',
  homeNo: '',
  meterSize: '',
  moo: '',
  name: '',
  peaName: '',
  postCode: '',
  province: '',
  regisDate: null,
  room: '',
  street: '',
  subDistrict: '',
  villageName: '',
  floor: '',
};

export const INITIAL_LINKAGENSB_UTIL_PEA: ILinkageNSBUtilPea = {
  caList: [EMPTY_CA_LIST],
  rowCount: '',
  status: '',
};

export const INITIAL_MARRIED_DETAIL: MarriedDetail = {
  femaleAge: 0,
  femaleDateOfBirth: 0,
  femaleFirstName: '',
  femaleFullnameAndRank: '',
  femaleLastName: '',
  femaleMiddleName: '',
  femaleNationalityCode: 0,
  femaleNationalityDesc: '',
  femaleOtherDocID: '',
  femalePID: 0,
  femaleTitleCode: 0,
  femaleTitleDesc: '',
  maleAge: 0,
  maleDateOfBirth: 0,
  maleFirstName: '',
  maleFullnameAndRank: '',
  maleLastName: '',
  maleMiddleName: '',
  maleNationalityCode: 0,
  maleNationalityDesc: '',
  maleOtherDocID: '',
  malePID: 0,
  maleTitleCode: 0,
  maleTitleDesc: '',
  marryDate: 0,
  marryID: 0,
  marryPlace: '',
  marryPlaceDesc: '',
  marryPlaceProvince: '',
  marryTime: 0,
  marryType: '',
};

export const INITIAL_MARRIED_LOGS: MarriedLogs = {
  allMarry: [],
  total: 0,
};

export const INITIAL_LINKAGE_PERSON_MARRIED: ILinkagePersonMarried = {
  marriedLogs: INITIAL_MARRIED_LOGS,
  marriedDetail: INITIAL_MARRIED_DETAIL,
};

export const INITIAL_MOVE: Move = {
  description: '',
  documentNo: '',
  house: {
    address: {
      alleyDesc: '',
      alleyWayDesc: '',
      districtDesc: '',
      houseNo: '',
      provinceDesc: '',
      roadDesc: '',
      subdistrictDesc: '',
      villageNo: '',
    },
    houseID: 0,
    houseOwner: '',
    moveDate: 0,
  },
  inform: {
    rcodeCode: '',
    rcodeDesc: '',
  },
  oldDateMoveIn: 0,
  own: {
    dateOfBirth: 0,
    father: {
      name: '',
      nationalityDesc: '',
      personalID: 0,
    },
    firstName: '',
    gender: '',
    lastName: '',
    middleName: '',
    mother: {
      name: '',
      nationalityDesc: '',
      personalID: 0,
    },
    nationalityDesc: '',
    personalID: 0,
    titleDesc: '',
  },
  petitionNo: '',
  terminateDate: 0,
  type: 0,
};

export const INITIAL_LINKAGE_MOVE_HOUSE: ILinkageMoveHouse = {
  movedIn: {
    move: [INITIAL_MOVE],
  },
  movedOut: {
    move: [INITIAL_MOVE],
  },
};

//Phone
export const INITIAL_LINKAGE_NSB_PHONE: ILinkageNSBPhone = {
  toTjsonResult: {
    responseResult: {
      responseCode: '',
      responseDateTime: '',
      responseDesc: '',
      responseOK: false,
    },
    totca: [],
  },
};

// Define initial state for Totca
export const INITIAL_TOTCA: Totca = {
  cA_Address_Detail: '',
  cA_CitizenID: '',
  cA_CompanyName: '',
  cA_CreatedDate: '',
  cA_Email: '',
  cA_FaxNo: '',
  cA_FaxNoExt: '',
  cA_Firstname: '',
  cA_ID: '',
  cA_Lastname: '',
  cA_MobileNo1: '',
  cA_MobileNo2: '',
  cA_PhoneExtNo: '',
  cA_PhoneNo: '',
  cA_Status: '',
  cA_Title: '',
  totba: [],
};

// Define initial state for Totba
export const INITIAL_TOTBA: Totba = {
  bA_AccountStatus: '',
  bA_Address_detail: '',
  bA_BillHanding: '',
  bA_Email: '',
  bA_FirstName: '',
  bA_FullName: '',
  bA_HomePhone: '',
  bA_ID: '',
  bA_LastName: '',
  bA_Mobile: '',
  bA_PaymentMethod: '',
  bA_TelephoneDept: '',
  bA_Title: '',
  totAsset: [],
};

// Define initial state for TOTAsset
export const INITIAL_TOT_ASSET: TOTAsset = {
  asset_Address_Detail: '',
  asset_EndDate: '',
  asset_No: '',
  asset_StartDate: '',
  asset_Status: '',
  asset_product: '',
  cdr: [],
};

// Define initial state for Cdr
export const INITIAL_CDR: Cdr = {
  cdR_Data: null,
  cdR_EventDest: null,
  cdR_EventDetail: null,
  cdR_EventDtm: null,
  cdR_EventSource: null,
  cdR_Result: '',
  cdR_SMS: null,
  cdR_Talk: null,
};

export const INITIAL_DATUM: Datum = {
  note: '',
  color: '',
  regDate: '',
  own_fname: '',
  taxExpired: '',
  occ_lname: '',
  own_pid: '',
  expDate: '',
  own_addr: '',
  vehTypeDesc: '',
  brnDesc: '',
  engBrnDesc: '',
  numEng: '',
  cc: '',
  mfgYear: '',
  wgtCar: '',
  oldLastSignDate: '',
  plate2: '',
  occ_addr: '',
  gasNo: '',
  carStatus: '',
  plate1: '',
  kindDesc: '',
  lastChkDate: '',
  holdFlag: '',
  modelName: '',
  cly: '',
  lpgGasExpireDate: '',
  offLocCode: '',
  noteDate: '',
  occ_fname: '',
  gasEndCertDate: '',
  occ_pid: '',
  own_lname: '',
  lastSignDate: '',
  fuelDesc: '',
  numBody: '',
};

// Define initial state for ILinkageNSBCar
export const INITIAL_LINKAGE_NSB_CAR: ILinkageNSBCar = {
  data: [INITIAL_DATUM],
  message: '',
  status: '',
};

export const INITIAL_LINKAGE_ADDRESS_HOUSE_DETAIL: AddressHouseDetail = {
  alleyCode: 0,
  alleyDesc: null,
  alleyEnglishDesc: null,
  alleyWayCode: 0,
  alleyWayDesc: null,
  alleyWayEnglishDesc: null,
  dateOfTerminate: 0,
  districtCode: 0,
  districtDesc: '',
  districtEnglishDesc: '',
  houseID: 0,
  houseNo: '',
  houseType: 0,
  houseTypeDesc: '',
  provinceCode: 0,
  provinceDesc: '',
  provinceEnglishDesc: '',
  rcodeCode: '',
  rcodeDesc: '',
  roadCode: 0,
  roadDesc: null,
  roadEnglishDesc: null,
  subdistrictCode: 0,
  subdistrictDesc: '',
  subdistrictEnglishDesc: '',
  villageNo: 0,
};

export const INITIAL_LINKAGE_PERSON_HOUSE_DETAIL: ILinkageNSBPersonHouseDetail = {
  data: [INITIAL_LINKAGE_ADDRESS_HOUSE_DETAIL],
};

export const INITIAL_PERSONAL_IN_HOUSE: PersonalInHouse = {
  age: 0,
  fullName: '',
  gender: '',
  personalID: 0,
  statusOfPersonDesc: '',
};

export const INITIAL_LINKAGE_PERSON_IN_HOUSE: ILinkageNSBPensonInHouse = {
  personalInHouse: [INITIAL_PERSONAL_IN_HOUSE],
  total: 0,
};

export const INITIAL_UTIL_PWA_RESPONSE = {
  apiResponse: [],
};

export const INITIAL_UTIL_PWA: PwaCAList = {
  address: '',
  amount: '',
  barcode: '',
  custcode: '',
  custname: '',
  docdate: '', // Default to current date
  docno: '',
  duedate: '', // Default to current date
  unit: '',
  use12m: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
  },
  vat: '',
};

export const INITIAL_LINKAGENSB_UTIL_PWA: ILinkageNSBUtilPwa = {
  caList: [INITIAL_UTIL_PWA],
};

//Gun
export const INITIAL_ALL_NAME: AllName = {
  amphorDesc: '',
  amphorDesc2: '',
  applicantType: 0,
  businessName: '',
  businessType: '',
  districtDesc: '',
  districtDesc2: '',
  docDate: 0,
  docID: '',
  docPlace: '',
  docPlaceDesc: '',
  docPlaceProvince: '',
  expireDate: 0,
  firstName: '',
  firstName2: '',
  fullNameAndRank: '',
  fullNameAndRank2: '',
  genderCode: 0,
  genderCode2: 0,
  genderDesc: '',
  genderDesc2: '',
  gunCharacteristic: '',
  gunProduct: '',
  gunRegistrationId: '',
  gunSerialNo: '',
  gunSize: '',
  gunType: '',
  hid: 0,
  hid2: 0,
  hidRcodeCode: '',
  hidRcodeCode2: '',
  hidRcodeDesc: '',
  hidRcodeDesc2: '',
  hno: '',
  hno2: '',
  lastName: '',
  lastName2: '',
  middleName: '',
  middleName2: '',
  personalId: 0,
  personalId2: 0,
  provinceDesc: '',
  provinceDesc2: '',
  signFullName: '',
  signTitleDesc: '',
  soi: '',
  soi2: '',
  thanon: '',
  thanon2: '',
  titleCode: 0,
  titleCode2: 0,
  titleDesc: '',
  titleDesc2: '',
  trok: '',
  trok2: '',
};

export const INITIAL_GUN_LICENSE: ILinkageNSBGunLicense = {
  allName: [INITIAL_ALL_NAME], // ใช้ค่าคงที่ที่สร้างไว้ข้างต้น
  processTimestamp: 0,
  remark: '',
  total: 0,
};

//Warrant
export const INITIAL_WARRANT: ILinkageNSBWarrant = {
  UPDATE_DATE: '',
  BH: '',
  STATUSSUSPECTARREST: '',
  BK: '',
  FIRSTNAMETH: '',
  FULLCASENO: '',
  SUSPECTAMPHUR: '',
  WRFILEID: '',
  CASETYPE: '',
  WR_DATE: '',
  WR_NO: '',
  SUSPECTRACE: '',
  WR_STARTDATE_EN: '',
  FULLHOMETOWN: '',
  WR_STARTDATE: '',
  POLICEFULLNAME: '',
  WRID: '',
  WR_AGE: '',
  UPDATE_DATE_EN: '',
  COURTID: '',
  CHANGEDDATE_EN: '',
  FULLCOURT: '',
  SUSPECTPROVINCE: '',
  FULLSTATION: '',
  SURNAMETH: '',
  SUSPECTFULLNAME: '',
  FULLPID: '',
  ORGCODE: '',
  FULLSUSNAME: '',
  SUSPECTTAMBON: '',
  WR_ENDDATE: '',
  DISPLAYCHARGEPERSON: '',
  WR_DATE_EN: '',
  STATUSWARRANT: '',
  CHANGEDDATE: '',
  WR_ENDDATE_EN: '',
  STATIONNAME: '',
  SUSPECTID: '',
  SUSPECTNATIONTH: '',
  ACCUSATION: '',
  COURT: '',
  CASENO: '',
  STATUSEXPIRED: '',
};

////TestPersonPhotoRegisByPid
export const INITIAL_Faceimage: ILinkagePersonPhotoRegisByPid = {
  errorMessage: null,
  errorNumber: null,
  image: '',
  mineType: '',
  personalID: 0,
};

//History

export const INITIAL_AllNameHistory: AllNameHistory = {
  age: 0,
  amphorDesc: '',
  dateOfBirth: 0,
  districtDesc: '',
  docDate: 0,
  docID: 0,
  docPlace: '',
  docPlaceDesc: '',
  docPlaceProvince: '',
  fatherFirstName: '',
  firstName: '',
  fullNameAndRank: '',
  genderCode: 0,
  genderDesc: '',
  hno: '',
  hrcode: '',
  hrcodeDesc: '',
  lastName: '',
  middleName: '',
  motherFirstName: '',
  nationalityCode: 0,
  nationalityDesc: '',
  newName: '',
  pid: 0,
  provinceDesc: '',
  requestDate: 0,
  requestID: 0,
  requestYear: 0,
  soiDesc: '',
  thanonDesc: '',
  titleCode: 0,
  titleDesc: '',
  trokDesc: '',
};

export const INITIAL_NameChange: NameChange = {
  allName: [INITIAL_AllNameHistory],
  total: 0,
};

export const INITIAL_StName: StName = {
  dateOfChange: 0,
  newValue: '',
  oldValue: '',
  rcodeCode: '',
  rcodeDesc: '',
};
export const INITIAL_NameSurnameChangeLogs: NameSurnameChangeLogs = {
  firstName: INITIAL_StName,
  lastName: INITIAL_StName,
};

export const INITIAL_History: ILinkagHistory = {
  errorMessage: null,
  errorNumber: null,
  nameChange: INITIAL_NameChange,
  surnameChange: INITIAL_NameChange,
  nameSurnameChangeLogs: INITIAL_NameSurnameChangeLogs,
};

//ILinkMaidennameandsurname

// ค่าเริ่มต้นของ AllnameMaidennameandsurname
export const INITIAL_AllnameMaidennameandsurname: ResponseData = {
  age: 0,
  dateOfBirth: 0,
  dateOfMoveIn: 0,
  englishFirstName: '',
  englishLastName: '',
  englishMiddleName: '',
  englishTitleDesc: '',
  fatherName: '',
  fatherNationalityCode: 0,
  fatherNationalityDesc: '',
  fatherPersonalID: '',
  firstName: '',
  fullnameAndRank: '',
  genderCode: 0,
  genderDesc: '',
  lastName: '',
  middleName: '',
  motherName: '',
  motherNationalityCode: 0,
  motherNationalityDesc: '',
  motherPersonalID: 0,
  nationalityCode: 0,
  nationalityDesc: '',
  ownerStatusDesc: '',
  pid: 0,
  recordNumber: 0,
  statusOfPersonCode: 0,
  statusOfPersonDesc: '',
  titleCode: 0,
  titleDesc: '',
  titleName: '',
  titleSex: 0,
  totalRecord: 0,
};

// ค่าเริ่มต้นของ ILinkMaidennameandsurname
export const INITIAL_ILinkMaidennameandsurname: ILinkMaidennameandsurname = {
  errorMessage: null,
  errorNumber: null,
  detailList: [], // กำหนดเป็น array ว่าง หรือ array ที่มี object DetailList
};

// ค่าเริ่มต้นของ DetailList
export const INITIAL_DetailList: DetailList = {
  responseContentType: '',
  responseData: INITIAL_AllnameMaidennameandsurname, // ใช้ object ที่กำหนดค่าเริ่มต้น
};

//Houseregistration
export const INITIAL_Houseregistration: ILinkHouseregistration = {
  errorMessage:           null,
  errorNumber:            null,
  alleyCode:              0,
  alleyDesc:              null,
  alleyEnglishDesc:       null,
  alleyWayCode:           0,
  alleyWayDesc:           null,
  alleyWayEnglishDesc:    null,
  dateOfTerminate:        0,
  districtCode:           0,
  districtDesc:           "",
  districtEnglishDesc:    "",
  houseID:                0,
  houseNo:                "",
  houseType:              0,
  houseTypeDesc:          "",
  provinceCode:           0,
  provinceDesc:           "",
  provinceEnglishDesc:    "",
  rcodeCode:              "",
  rcodeDesc:              "",
  roadCode:               0,
  roadDesc:               null,
  roadEnglishDesc:        null,
  subdistrictCode:        0,
  subdistrictDesc:        "",
  subdistrictEnglishDesc: "",
  villageNo:              0,
}

//Birthcertificate
export const INITIAL_Birthcertificate: ILinkBirthcertificate = {
  errorMessage:                     null,
  errorNumber:                      null,
  authorityIssuing:                 "",
  dateOfBirth:                       0,
  dateOfNotifying:                   0,
  fatherName:                       "",
  fatherNationalityDesc:            "",
  fatherPersonalID:                  0,
  firstName:                        "",
  gender:                           "",
  hospitalName:                     "",
  houseRegistrationAlleyDesc:       "",
  houseRegistrationAlleyWayDesc:    "",
  houseRegistrationDistrictDesc:    "",
  houseRegistrationNo:              "",
  houseRegistrationProvinceDesc:    "",
  houseRegistrationRoadDesc:        "",
  houseRegistrationSubdistrictDesc: "",
  houseRegistrationVillageNo:       "",
  lastName:                         "",
  middleName:                       "",
  motherName:                       "",
  motherNationalityDesc:            "",
  motherPersonalID:                 0,
  nationality:                      "",
  officerName:                      "",
  personInformAge:                  0,
  personInformName:                 "",
  personInformPersonalID:           0,
  personInformRelation:             "",
  personalID:                        0,
  placeOfBirth:                     "",
  placeOfBirthAlleyCode:             0,
  placeOfBirthAlleyDesc:            null,
  placeOfBirthAlleyWayCode:          0,
  placeOfBirthAlleyWayDesc:         null,
  placeOfBirthDistrictCode:          0,
  placeOfBirthDistrictDesc:         "",
  placeOfBirthNo:                    0,
  placeOfBirthProvinceCode:          0,
  placeOfBirthProvinceDesc:         "",
  placeOfBirthRoadCode:              0,
  placeOfBirthRoadDesc:             null,
  placeOfBirthSubdistrictCode:       0,
  placeOfBirthSubdistrictDesc:      "",
  placeOfBirthVillageNo:             0,
  titleDesc:                        "",
}

//Deathcertificate
export const INITIAL_Deathcertificate: ILinkDeathcertificate = {
  errorMessage:                null,
  errorNumber:                 null,
  addressAlleyDesc:            "",
  addressAlleyWayDesc:         "",
  addressDistrictDesc:         "",
  addressHouseNo:              "",
  addressProvinceDesc:         "",
  addressRoadDesc:             "",
  addressSubdistrictDesc:      "",
  addressVillageNo:            0,
  age:                         0,
  authorityIssuing:            "",
  causeOfDeath:                "",
  dateOfDeath:                 0,
  dateOfNotifying:             0,
  documentNo:                  "",
  fatherName:                  "",
  fatherPersonalID:            0,
  firstName:                   "",
  fullnameAndRank:             "",
  genderCode:                  0,
  genderDesc:                  "",
  lastName:                    "",
  marriageStatusCode:          0,
  marriageStatusDesc:          "",
  middleName:                  "",
  motherName:                  "",
  motherPersonalID:            0,
  nationalityCode:             0,
  nationalityDesc:             "",
  placeOfDeathAlleyDesc:       "",
  placeOfDeathAlleyWayDesc:    "",
  placeOfDeathDistrictDesc:    "",
  placeOfDeathHouseNo:         0,
  placeOfDeathProvinceDesc:    "",
  placeOfDeathRoadDesc:        "",
  placeOfDeathSubdistrictDesc: "",
  placeOfDeathVillageNo:       0,
  titleCode:                   0,
  titlePRINT:                  "",
}


//Arrestwarrant
export const INITIAL_LINK_ARRESTWARRANT: ILinkArrestwarrant = {
  errorMessage: null,
  errorNumber: null,
  warantList: [
    {
      updatE_DATE: '',
      bh: '',
      statussuspectarrest: '',
      bk: '',
      firstnameth: '',
      fullcaseno: '',
      suspectamphur: '',
      wrfileid: '',
      casetype: '',
      wR_DATE: '',
      wR_NO: '',
      suspectrace: '',
      wR_STARTDATE_EN: new Date(),
      fullhometown: '',
      wR_STARTDATE: '',
      policefullname: '',
      wrid: '',
      wR_AGE: '',
      updatE_DATE_EN: new Date(),
      courtid: '',
      changeddatE_EN: new Date(),
      fullcourt: '',
      suspectprovince: '',
      fullstation: '',
      surnameth: '',
      suspectfullname: '',
      fullpid: '',
      orgcode: '',
      fullsusname: '',
      suspecttambon: '',
      wR_ENDDATE: '',
      displaychargeperson: '',
      wR_DATE_EN: new Date(),
      statuswarrant: '',
      changeddate: '',
      wR_ENDDATE_EN: new Date(),
      stationname: '',
      suspectid: '',
      suspectnationth: '',
      accusation: '',
      court: '',
      caseno: '',
      statusexpired: '',
    },
  ],
};

//Caseinformation
export const INITIAL_LINK_CASEINFORMATION: ILinkCaseinformation = {
  errorMessage: null,
  errorNumber: null,
  caseInfoList: [
    {
      nationality: '',
      fullmobilenum: '',
      bh: '',
      bk: '',
      firstnameth: '',
      fullcaseno: '',
      crimecaseyear: '',
      displayresultcasE_INVESTIGATOR: '',
      statusvictimorsuspect: '',
      gender: '',
      age: '',
      passportnumber: '',
      ccid: '',
      fullpasid: '',
      surnameth: '',
      mobilenumber: '',
      suspectfullname: '',
      fullpid: '',
      orgcode: '',
      fullsusname: '',
      displaychargeperson: '',
      displayresultC_HEADINVESTIGATO: '',
      race: '',
      changeddate: new Date(),
      stationname: '',
      occupation: '',
      suspectid: '',
      aliasname: '',
      statusaboutwarrant: '',
      psid: '',
      caseno: '',
      crimecaseno: '',
    }
  ],
};
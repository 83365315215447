import React, { FC, FocusEvent, ChangeEvent } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputTemplate from './InputTemplate';
import { RadioOption } from './type';
import { TRadio } from 'shared/types';

interface IRadioProps {
  label?: string;
  className?: string;
  error?: string;
  info?: string;
  disabled?: boolean;
  options?: RadioOption[];
  value?: TRadio | number;
  required?: boolean;
  onChange?(_event: ChangeEvent<HTMLElement>, value: string): void;
  onBlur?(_event: FocusEvent<HTMLDivElement>): void;
  defaultValue?: number;
}

const Input: FC<IRadioProps> = ({ label, className, error, info, disabled, value, options, required, onChange, onBlur, 
  defaultValue,
}) => {
  return (
    <InputTemplate className={className} label={label} info={info} error={error} required={required}>
      <RadioGroup row value={value} onChange={onChange} onBlur={onBlur}>
        {options &&
          options.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio color="primary" />}
              label={option.label}
              labelPlacement="end"
              key={option.value}
              disabled={option.disabled}
              // disabled={disabled}
            />
          ))}
      </RadioGroup>
    </InputTemplate>
  );
};

export default Input;
